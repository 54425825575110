export default {
  data() {
    return {
      user_email: '',
      code: '',
      time: 60,
      isGetCode: false,
      //是否获取验证码
      timer: '' //定时器
    };
  },

  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    // 获取验证码
    getCode() {
      if (!this.user_email) {
        this.$message.info(this.$t('tips.emailNumberCannotBeEmpty'));
        return;
      }
      let data = {
        type: 7,
        is_test: 0,
        user_email: this.user_email
      };
      this.$http.getCode(data).then(res => {
        if (res.code == 1) {
          this.$message.success(this.$t('sendSuccess'));
          this.isGetCode = true;
          this.timer = setInterval(() => {
            if (this.time == 1) {
              clearInterval(this.timer);
              this.time = 60;
              this.isGetCode = false;
            }
            this.time--;
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      }).catch(v => {});
    },
    confirmClick() {
      if (!this.user_email) {
        this.$message.info(this.$t('tips.emailNumberCannotBeEmpty'));
        return;
      }

      // if (!this.code) {
      // 	this.$message.info(this.$t('tips.codeCannotBeEmpty'))
      // 	return;
      // }

      this.$http.bindingEmail({
        user_email: this.user_email,
        code: this.code,
        // step: 2,
        type: 7
      }).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          let newUserinfo = {
            ...this.$store.state.userinfo,
            user_email: this.user_email
          };
          this.$store.commit('edituserinfo', newUserinfo);
          this.$router.back();
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  }
};