var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "person_infor"
  }, [_c('div', {
    staticClass: "login_top"
  }, [_vm._m(0), _c('div', {
    staticClass: "login_title"
  }, [_vm._v(_vm._s(_vm.$t('set.bindingEmail')))])])]), _c('div', {
    staticClass: "cur_account"
  }), _c('div', {
    staticClass: "input_item"
  }, [_vm._m(1), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user_email,
      expression: "user_email"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('login.inputEmail')
    },
    domProps: {
      "value": _vm.user_email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.user_email = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.confirmClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('confirm')) + " ")])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/flag.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "input_item_icon"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/login/email.png")
    }
  })]);
}];
export { render, staticRenderFns };